import React from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';


const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

const Hero = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {


  const outerClasses = classNames(
    'hero section center-content',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'hero-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container-sm">
        <div className={innerClasses}>
          <div className="hero-content">
          <h1 className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="200">
            Succeed in <span className="text-color-primary">Guild Wars 2 </span> Dragon Slaying
            </h1>
          </div>
               
          <p  ><span
>Succeed in Guild Wars 2 Dragon
Slaying </span></p>

<p  ><span
>Thief</span></p>

<p  ><span
>There are couple of options to go
power or condi dps, I personally like power as it helps in open world too and
it's in general easier to play. But playing as a thief unfortunatelly you have
to get staff weapon which is my least favorite for this proffesion. Also there
is only one build playing with a staff.</span></p>

<p  ><span
>That brings to my final decision
to pick condi dps. </span></p>

<p  ><span
>Pros: much better dps on big
bosses or moving targets like in fractals, raids, New proffesion specter uses
same attributes if you would like to try this out, if you fuck up and miss
important tactic to deal damage, your damage over time still ticks and you
won't lose dps so much.</span></p>

<p  ><span
>Cons: average clear in open
world, takes longer to kill, longer to set up the dps, Melee.</span></p>

<p  ><span
>&nbsp;</span></p>

<p  ><span
>For the gear and build:</span></p>

<p  ><span
>(Default - stable build)
Daredevil Condi: https://metabattle.com/wiki/Build:Daredevil_-_Condi_DPS</span></p>

<p  ><span
>(To try out - op now, might get
nerfed) Specter Condi : https://metabattle.com/wiki/Build:Specter_-_Condi_DPS</span></p>

<p  ><span
>&nbsp;</span></p>

<p  ><span
>You have to get Viper armour
which gives you Condition Damage and Expertise attributes (expertise increases
condi duration).</span></p>

<p  ><span
>&nbsp;</span></p>

<p  ><span
>Now let's get in to the gearing.</span></p>

<p  ><span
>&nbsp;</span></p>

<p  ><span
>Before following the guide how to
obtain, there is simplier, cheaper and &quot;kind a faster&quot; way to get all
of the gear, but depends how much you played specific game modes.</span></p>

<p  ><span
>&nbsp;</span></p>

<p  ><span
>sPVP:</span></p>

<p  ><span
>Structured PvP vendor for
Ascended or Exotic gear is located inside PvP Lobby, Market Waypoint.</span></p>

<p  ><span
>Check for Ascended Shards of
Glory - main currency for armour and trinket items to buy. </span></p>

<p  ><span
>Shard of Glory - cheap stuff you
can buy from Auction house. Usually 3g 25s per 250 stack.</span></p>

<p  ><span
>PvP League Ticket - for Back
peace - check if you can afford at least Exotic.</span></p>

<p  ><span
>Grandmaster Weaponsmith's and
Leatherworker's marks (you'll need 3-4 of these per item) - from PvP ranked
games chests reward track, limited 3 per PvP season. OR Weaponsmith and
Leatherworking Crafting level to 500. (Around 9g to craft)</span></p>

<p  ><span
>Recipes to craft are also there.</span></p>

<p  ><span
>&nbsp;</span></p>

<p  ><span
>Fractals:</span></p>

<p  ><span
>Fractal vendor is in Fractals of
the mists lobby located where all the vendors are.</span></p>

<p  ><span
>Fractal Relic - you get for
participating in Fractals - higher level, the more you get.</span></p>

<p  ><span
>Pristine Fractal relic - you get
it by completing Fractal Dailies and challenge modes (hard without a gear).</span></p>

<p  ><span
>Fractal research pages - are time
gated item obtainable as rewards for completing daily recommended and challenge
mode fractals.</span></p>

<p  ><span
>Stabilizing Matrix - you can buy
from Auction House or Salvaging Ascended items with Ascended salvage tool. 0g
26s each.</span></p>

<p  ><span
>&nbsp;</span></p>

<p  ><span
>WvW: DF I KNOW</span></p>

<p  ><span
>RAIDS: NONE WANTS ME THERE</span></p>

<p  ><span
>STRIKES: DUNNO MATE ITS GOOD FOR
MONEY I GUESS</span></p>

<p  ><span
>Open world and story missions:
Have a look, plenty of missions give items if you do the achievements. Some of
them gives Ascended too! Requires you to research personally.</span></p>

<p  ><span
>&nbsp;</span></p>

<p  ><span
>Exotic Armour: Exotic Viper
armour can be purchased from Auction House:</span></p>

<p  ><span
>17-18g per peace</span></p>

<p  ><span
>18g x6 peaces = 108g - full
armour set</span></p>

<p  ><span
>Or Laurel Vendor located in any
game mode vendor locations. Only if you cannot afford Ascended items!</span></p>

<p  ><span
>&nbsp;</span></p>

<p  ><span
>Armour runes:</span></p>

<p  ><span
>Superior rune of the afflicted </span></p>

<p  ><span
>0g 16s x6 = costs nothing</span></p>

<p  ><span
>&nbsp;</span></p>

<p  ><span
>Ascended Armour: Viper armour
crafted by having 500 lvl of Leatherworking or buying from vendors with
currency. Keep in mind it's always good to have crafting job. Also you get
experience while crafting. Crafting recipes you get from game mode vendors
trading with currency. Or just craft Grandmaster marks unlocked at 500 lvl.</span></p>

<p  ><span
>&nbsp;</span></p>

<p  ><span
>Crafting:</span></p>

<p  ><span
>Guide to levelup Leatherworking: </span></p>

<p  ><span
>1 to 400:
https://gw2crafts.net/leatherworking_fast.html</span></p>

<p  ><span
>400 to 500:
https://gw2crafts.net/leatherworking_400.html</span></p>

<p  ><span
>Can buy mats from Laurel Vendor.
But don't waste laurels, only if you can't buy anything else. Best value is for
tier VI Heavy Crafting Bag.</span></p>

<p  ><span
>&nbsp;</span></p>

<p  ><span
>Exotic Weapons (dagger &amp;
dagger): Exotic Viper weapons can be purchased from Auction House:</span></p>

<p  ><span
>16g per peace</span></p>

<p  ><span
>16g x 4 peaces = 64g - full
weapon set (yes you need both slots, when you swap weapons you get sigil proc)</span></p>

<p  ><span
>Or Laurel Vendor located in any
game mode vendor locations. Only if you cannot afford Ascended items!</span></p>

<p  ><span
>&nbsp;</span></p>

<p  ><span
>Weapon sigils:</span></p>

<p  ><span
>Superior Sigil of Earth 0g 25s x2
= 0g 50s</span></p>

<p  ><span
>Superior Sigil of Doom 4g x2 = 8g</span></p>

<p  ><span
>&nbsp;</span></p>

<p  ><span
>Equip Each different sigil for
each weapon slot, for eg first dagger equipment set has to be with sigil of
earth and sigil of doom. (NOT BOTH WITH EARTH) - warning for dummies. wink
wink.</span></p>

<p  ><span
>&nbsp;</span></p>

<p  ><span
>Ascended Weapons: Viper Weapons
crafted by having 500 lvl of Weaponsmith or buying from vendors with currency.
Keep in mind it's always good to have crafting job. Also you get experience
while crafting. Crafting recipes you get from game mode vendors trading with
currency. &nbsp;Or just craft Grandmaster marks unlocked at 500 lvl.</span></p>

<p  ><span
>&nbsp;</span></p>

<p  ><span
>Crafting:</span></p>

<p  ><span
>Guide to levelup Weaponsmith (I
usually buy weapons from game mode vendors): </span></p>

<p  ><span
>1 to 400:
https://gw2crafts.net/weaponcraft_fast.html</span></p>

<p  ><span
>400 to 500:
https://gw2crafts.net/weaponcraft_400.html</span></p>

<p  ><span
>Can buy mats from Laurel Vendor.
But don't waste laurels, only if you can't buy anything else. Best value is for
tier VI Heavy Crafting Bag.</span></p>

<p  ><span
>&nbsp;</span></p>

<p  ><span
>Trinkets (Try to find any Exotic
in Auction House but give all energy to buy Ascended as it is most expensive
peaces for gear):</span></p>

<p  ><span
>2x Rings, Necklace, 2x
Accessories - Buy from any game mode or laurel vendors. Buy INFUSED only rings
to get an infusion slot for playing in fractals and get extra attributes.
Infusion with attributes costs around 50g. Buy only to maximize your armour
after you got full Ascended.</span></p>

<p  ><span
>&nbsp;</span></p>

<p  ><span
>Backpeace: </span></p>

<p  ><span
>It's kind a complicated to go for
a specific, look for one and play by your own style, all are hard to get. Check
Backpeace items: https://wiki.guildwars2.com/wiki/Ascended_back_item</span></p>

<p  ><span
>Also go for selectable prefixes
attributes items. Find the recipe from any game mode vendors or open world
vendors and craft it.</span></p>

<p  ><span
>&nbsp;</span></p>

<p  ><span
>Infusions (for fractlas):</span></p>

<p  ><span
>If you have some of Ascended
gear, buy infusions so you can procceed clearing higher fractal levels. Higher
level the better rewards.</span></p>

<p  ><span
>Most optimal: Get +7 infusion
from Auction House. 1g 18s per peace.</span></p>

<p  ><span
>&nbsp;</span></p>

<p  ><span
>Legendary crafting:</span></p>

<p  ><span
>JUST DON'T. BIG FLEX. EMPTY
POCKETS. TIME CONSUMING.</span></p>

<p  ><span
>Same stats as ascended but you
can swap attributes anytime. Good for playing multiple builds. </span></p>

<p  ><span
>&nbsp;</span></p>

<p  ><span
>Gold farming: </span></p>

<p  ><span
>Structured PvP Automated
Tournaments: Find a team in LFG (button O by default). By Participation you
might get at least 5 gold.</span></p>

<p  ><span
>Run Dailies. Under
Hero&gt;Achievement&gt;Daily. Pick your favourites. For Eg.: sPvP, Fractals,
WvW.</span></p>

<p  ><span
>World boss farms (each boss 1
time a day): </span></p>

<p  ><span
>https://wiki.guildwars2.com/wiki/Event_timers</span></p>

<p  ><span
>&nbsp;</span></p>

<p  ><span
>More detailed info for gold
farming:</span></p>

<p  ><span
>https://metabattle.com/wiki/Guide:Ways_to_Earn_Gold</span></p>

<p  ><span
>&nbsp;</span></p>

<p  ><span
>Or just swipy swipy.</span></p>

        </div>
      </div>
    </section>
  );
}

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default Hero;